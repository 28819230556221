@import '../../variables.scss';

.checklistInput {
  padding: 10px 0px 5px;
  .checklistTable {
    table-layout: fixed;
    width: 100%;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 0px 5px;
        }
      }
    }
    tbody {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      tr {
        &:nth-child(2n-1) {
          td {
            padding: 15px 5px 5px;
            border-top: 1px solid #dddddd;
          }
        }
        &:nth-child(2n) {
          td {
            padding: 5px 5px 15px;
          }
        }
        td {
          overflow: visible;
          &>* {
            padding: 0px;
          }
          &.control {
            padding: 0px 5px;
            overflow: visible;
          }
        }
      }
    }
  }
  .actionButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .material-icons {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  button {
    margin-top: 5px;
    margin-left: 5px;
  }
}
