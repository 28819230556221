.userAvatarView {
  vertical-align: top;
  border: 1px solid rgba(255,255,255,0.25);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  color: #ffffff;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
