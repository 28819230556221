.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}

.slide-enter {
  transform: translate(-100%, 0);
}
.slide-enter-active {
  transform: translate(0%, 0);
  transition: transform 350ms;
}
.slide-exit {
  transform: translate(0%, 0);
}
.slide-exit-active {
  transform: translate(-100%, 0);
  transition: transform 350ms;
}

.slide-up-enter {
  transform: translate(0, 100%);
}
.slide-up-enter-active {
  transform: translate(0, 0%);
  transition: transform 350ms;
}
.slide-up-exit {
  transform: translate(0, 0%);
}
.slide-up-exit-active {
  transform: translate(0, 100%);
  transition: transform 350ms;
}
