@import '../../_variables.scss';

.framePopup {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 36px);
      position: relative;
      max-width: 768px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px,
        rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px,
        rgba(0, 0, 0, 0.02) 0px 12px 16px;
      max-height: 100%;
      border-radius: 8px;
      transition: all 300ms ease 0s;
      padding: 20px;
      position: relative;
      .popupTitle {
        padding-right: 40px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.22;
        color: #333333;
      }
      .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(../../../../public/images/icons/close.svg) no-repeat center;
          mask-size: contain;
        }
      }
      iframe {
        border: none;
        margin-top: 10px;
        width: 100%;
        height: calc(100vh - 125px);
      }
    }
  }
}

@media all and (max-width: 768px) {
  .framePopup {
    .overlay {
      .popupWindow {
        width: 100%;
        border-radius: 0px;
        iframe {
          height: calc(100vh - 68px);
        }
      }
    }
  }
}
