.dateInput {
  padding: 8px 0px;
  &.simplified {
    input,
    input[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  input {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #333333;
    &::placeholder {
      color: #999999;
    }
  }

  .rdtPicker td, .rdtPicker th{
    width: 30px !important;
    padding: 5px !important;
  }

  .rdtDay, .dow {
    width: 30px !important;
  }

}
