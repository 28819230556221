@import '../_variables.scss';

.objectListView {
  .objectsListTable {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    thead {
      tr {
        th {
          height: 40px;
          border: none;
          color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 10px;
          height: 41px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
          overflow: hidden;
          white-space: nowrap;
          border-top: 1px solid #dddddd;
          height: 41px;
          &.control {
            padding: 0px 5px;
            overflow: visible;
          }
        }
      }
    }
  }
}

.createButton {
  position: fixed;
  right: 35px;
  bottom: 40px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  color: #ffffff;
  background-color: $primaryColor;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 768px) {
  .objectListView {
    .card {
      margin-bottom: 30px;
      table {
        margin-top: -10px;
        margin-bottom: 0px;
        tbody {
          tr {
            &:first-child {
              td {
                border-top: none;
              }
            }
            td {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
