.statisticsView {
  max-width: 100%;

  .label {
    font-size: 1em;
    font-weight: normal;
    color: #999999;
  }

  .value {
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    margin-bottom: 3px;
  }

  .bar {
    font-weight: 300;
    border-collapse: separate;
    border-spacing: 0px;
    text-align: left;
    color: #328e86;
    font-size: 1em;
    box-sizing: inherit;
    display: block;
    line-height: 1;
    position: relative;
    background: #328e86;
    border-radius: 0em;
    transition: width 0.3s ease, background-color 0.3s ease;
    height: 0.8em;
    background-color: #328e86;
    transition-duration: 300ms;
    border-radius: 20px 0 0 20px;
  }

  .green {
    line-height: 1.33;
    font-weight: 300;
    border-collapse: separate;
    border-spacing: 0px;
    text-align: left;
    box-sizing: inherit;
    color: #328e86;
    position: relative;
    display: block;
    max-width: 100%;
    border: none;
    box-shadow: none;
    background: #cccccc;
    padding: 0em;
    border-radius: 0em;
    font-size: 1em;
    margin: 0em 0em 2.5em;
    overflow: hidden;
  }

  .progress {
    position: relative;
    display: block;
    max-width: 100%;
    border: none;
    margin: 2.5em 0;
    box-shadow: none;
    background: #cccccc;
    padding: 0;
    border-radius: 20px;
    .progress {
        margin: 0;
    }
  }

  .statisticsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
