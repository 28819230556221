.proposalView {

  button {
    border-radius: 0px;
  }

  .expiredDate {
    display:inline-block;
  }

  .createdDate  {
    color: #666;
    font-size: 0.75rem;
    margin-bottom: 10px;
  }

  .dateInput input {
    height: 34px;
    border-radius: 3px 0 0 3px;
    max-width: 100px;
  }

  max-width: 100%;

  .title {
    color: #00b2f3;
    text-decoration: none;
  }

  .status {
    display: inline-block;
    line-height: 1;
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    padding: 0.4rem 0.8rem;
    color: #666;
    font-weight: bold;
    box-sizing: border-box;
    color: #999999;
    text-align: left;
    white-space: nowrap;
    margin-top: 20px;
    font-size: 12px;
    border-radius: 5px;
    margin: 0 10px;
  }

  .live {
    background-color: #3aa097;
    color: #ffffff;
  }

  .draft {
    background-color: #999;
    color: #ffffff;
  }

  .pending {
    background-color: #FFE21F;
  }

  .paid {
    background-color: #3AA097;
    color: #ffffff;
  }

  .refunded {
    background-color: #CDC6FF;
  }

  .rejected {
    background-color: #D95C5C;
  }

  .expired {
    background-color: #FF851B;
    color: #ffffff;
  }

  .complete {
    background-color: #0000FF;
    color: #ffffff;
  }

  .failed {
    background-color: #D9499A;
    color: #ffffff;
  }

  .proposalFail, .proposalComplete {
    border-radius: 3px 0 0 3px;
  }

  .proposalReopen {
    border-radius: 3px;
  }

  .proposalFail, .proposalReject, .proposalClose {
    display: inline-block;
    min-height: 1em;
    text-transform: capitalize;
    line-height: 1;
    text-align: center;
    max-width: 100%;
    background-color: #d95c5c;
    color: #ffffff;
    padding: 1em 2em;
    font-size: 12px;
  }

  .proposalComplete, .proposalApprove, .proposalReopen {
    display: inline-block;
    min-height: 1em;
    text-transform: capitalize;
    line-height: 1;
    text-align: center;
    max-width: 100%;
    background-color: #328e86;
    color: #ffffff;
    float: left;
    padding: 1em 2em;
    font-size: 12px;
  }

  .proposalExtend {
    display: inline-block;
    min-height: 1em;
    text-transform: capitalize;
    line-height: 1;
    text-align: center;
    max-width: 100%;
    float: left;
    background-color: #eeeeee;
    color: #328e86;
    padding: 1em 2em;
    font-size: 12px;
  }

  .proposalEdit {
    display: inline-block;
    min-height: 1em;
    text-transform: capitalize;
    line-height: 1;
    text-align: center;
    max-width: 100%;
    background-color: #f2c61f;
    color: #ffffff;
    padding: 1em 2em;
    font-size: 12px;
    text-decoration: none;
    border-radius: 0 3px 3px 0;
  }

  .value {
    font-weight: 300;
    line-height: 1em;
    color: #333333;
    text-align: left;
    white-space: nowrap;
    text-transform: initial;
  }

  .updateDate, .updateRaisedAmount {
    display: inline-block;
    min-height: 1em;
    text-transform: capitalize;
    line-height: 1;
    text-align: center;
    max-width: 100px;
    height: 34px;
    background-color: #eeeeee;
    color: #328e86;
    flex: 1 0 auto;
    margin-top: 8px;
    box-shadow: 0px 0px 0px 1px #dfdfdf inset,;
    background: #ffff;
    margin-left: -1px;
    padding: 8px 16px;
    border-radius: 0 3px 3px 0;
  }

  .suggestedPrice {
    text-align: left;
    display: inline-block;
    line-height: 1;
    background-color: #e8e8e8;
    padding: 0.6em 0.8em;
    color: #666;
    font-weight: bold;
    border-radius: 0.2857rem;
    box-sizing: border-box;
    flex: 1 0 auto;
    max-width: 200px;
    height: 34px;
    min-height: 34px;

    margin-top: 8px;
  }

  .or:before {
    display: inline-block;
    position: relative;
    text-align: center;
    border-radius: 100%;
    content: 'or';
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    text-shadow: none;
    margin-left: -12px;
    margin-right: -6px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    color: #999999;
    font-weight: bold;
  }

  .tags {
    padding-bottom: 20px
  }

  .tagged {
    border-spacing: 0px;
    text-align: left;
    display: inline-block;
    line-height: 1;
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    color: #666;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    padding: 2px 2px 0 10px;
  }

  .availableTag {
    color: #009fda;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .untag {
    color: #009fda;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    cursor: pointer;
    color: #000;
    margin: -2px 0 0 5px;
    font-size: 18px;
    padding: 4px;
  }

  .sharesNumber {
    margin-right: 5px;
  }

  .shares {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 13px;
    color: #444;
  }

  .suggested {
    display: flex;
  }

  .addTag {
    font-size: 0.9rem;
  }

  .tagTitle {
    font-size: 0.9rem;
  }

  .proposalContainer {
    display: flex;
  }

  .actionsContainer {
    font-family: Arial;
    line-height: 1.33;
    font-weight: 300;
    color: rgba(0,0,0,0.8);
    border-spacing: 0px;
    font-size: 1em;
    text-align: left;
    box-sizing: inherit;
    vertical-align: middle;
    margin: 0em 0.25em 0em 0em;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .textInput input {
    height: 34px;
  }
}

@media all and (max-width: 768px) {
  .proposalView {
    .proposalContainer {
      display: block;
    }
  }
}
