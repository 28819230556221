$primaryColor: #328e86;
$secondaryColor: #666;
$darkerColor: #096DD9;
$color1: #70798b;
$color2: #8C8C8C;
$color3: #83cfcb;
$color4: #E6F7FF;
$outlineColor: #f1f1f1;
$highlightColor: #ffeeea;
$successColor: #74ae3b;
$errorColor: #e41407;
$mainBackgroundColor: #FAFAFA;
$secondaryBackgroundColor: #F5F5F5;
