.imageInput {
  padding: 5px 0px;
  img {
    display: block;
    width: 100%;
    max-width: 350px;
    &+button {
      margin-top: 20px;
    }
  }
  input[type='file'] {
    display: none;
  }
}
