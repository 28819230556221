.typeformDataView {
  padding: 10px 0px;
  .noDataPlaceholder {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    font-weight: 500;
  }
  .typeformTable {
    table-layout: fixed;
    width: 100%;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    margin-bottom: 10px;
    thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 0px 5px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px 5px;
          overflow: visible;
          border-top: 1px solid #dddddd;
          &>* {
            padding: 0px;
          }
          &.control {
            padding: 0px 5px;
            overflow: visible;
          }
        }
      }
    }
  }
}
