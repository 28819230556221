@import '../../_variables.scss';

.documentsList {
  padding: 10px 0 25px;
  position: relative;
  input[type='file'] {
    display: none;
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
  }
  .listPlaceholder {
    padding: 25px 0 10px;
    font-size: 16px;
    font-weight: 500;
    color: #999999;
    text-align: center;
  }
  .listContainer {
    padding: 15px 0 0;
  }
  .addButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .documents {
    display: flex;
    justify-content: space-between;
  }
  .uploadLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .uploadLink {
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: $primaryColor;
      span {
        margin-right: 5px;
      }
    }
  }
  .docImage {
    height: 200px;
    width: 200px;
    background-size: cover;
    background-position: center;
  }

  button.chowzb {
    box-shadow: none;
    background-color: transparent;
  }

  button.chowzb:hover:enabled {
    box-shadow: none;
    background-color: transparent;
    color: black;
  }

  button.chowzb:focus:enabled {
    box-shadow: none;
    background-color: transparent;
  }

  button.hCVuYx:disabled {
    background-color: transparent;
  }

  button.brcpxa {
    background-color: $primaryColor;
    box-shadow: 0 0 1px 3px $primaryColor;
  }

  button.sc-gKAblj {
    padding:initial;
  }
}

@media all and (max-width: 768px) {
  .documentsList {
    padding-top: 0;
    .addButton {
      bottom: 70px;
    }
    .uploadLinks {
      flex-direction: column;
      .uploadLink {
      }
    }
  }
}
