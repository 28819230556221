@import '../_variables.scss';

.settingsView {
  .inputsBlock {
		display: flex;
		flex-direction: column;
		.inputContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			&.hidden {
				display: none;
			}
			label {
				width: 20%;
				max-width: 170px;
				display: block;
				font-size: 12px;
				font-weight: 700;
				color: #666666;
				text-transform: uppercase;
				text-align: right;
				padding-right: 15px;
			}
			.inputElem {
				flex: 1 0;
				width: 80%;
				.inputError {
					color: $errorColor;
					font-size: 14px;
					margin: -2px 0px 2px;
				}
			}
		}
	}
	.buttonContainer {
		padding: 15px 0px 5px;
		&::before {
			content: '';
			display: inline-block;
			width: 20%;
			max-width: 170px;
		}
	}
}
