.changesInput {
  padding: 10px 0px;
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    &+table {
      margin-top: 30px;
    }
    th {
      border: none;
      color: #999999;
      font-weight: 400;
      text-align: left;
      padding: 10px;
      height: 41px;
    }
    td {
      padding: 10px;
      overflow: hidden;
      white-space: nowrap;
      border-top: 1px solid #dddddd;
      height: 41px;
    }
  }
}
