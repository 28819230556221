@import '../../_variables.scss';

.pagination {
  padding: 24px 0px;
  .pageButton, .ellipsis {
    display: inline-block;
    vertical-align: top;
    color: #999999;
    height: 36px;
    margin: 3px;
    padding: 0px 11px;
    position: relative;
    font-size: 14px;
    min-width: 36px;
    background: transparent;
    font-weight: 400;
    line-height: 36px;
    border-radius: 36px;
    letter-spacing: unset;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    user-select: none;
    &:hover {
      background: rgba(200, 200, 200, 0.3);
    }
    &.selected {
      color: #FFFFFF;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
      border-color: $primaryColor;
      background-color: $primaryColor;
    }
    &.prev {
      margin-left: 0px;
    }
    &.next {
      margin-right: 0px;
    }
  }
  .ellipsis {
    cursor: initial;
    &::before {
      content: '...';
    }
    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }
}
