@import '../_variables.scss';

.notificationsView {
  max-width: 520px;
  margin: 10px auto;
  .notificationsList {
    padding: 4px 0px !important;
    .notificationsPlaceholder {
      padding: 10px 16px 8px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .notificationItem {
      padding: 16px;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 650ms;
      &.unread {
        background-color: $highlightColor;
      }
      &:not(:first-child) {
        border-top: 1px solid #eeeeee;
      }
      .notificationContent {
        vertical-align: top;
        display: inline-block;
        width: 100%;
        .notificationBody {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #666666;
          b {
            font-weight: 600;
          }
        }
        .notificationDate {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #999999;
        }
      }
    }
  }
}
