@import '../../variables.scss';

.userChats {
  padding: 5px 0px 5px;
  .chatItem {
    cursor: pointer;
    padding: 10px 5px;
    border-bottom: 1px solid #dddddd;
    background-color: transparent;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
      background-color: #eeeeee;
    }
  }
}

.chatContent {
  width: 100%;
  height: calc(100vh - 280px);
  overflow-y: auto;
  padding: 0px 5px;
  .messageContainer {
    padding: 8px 0px;
    display: flex;
    .messageItem {
      position: relative;
      max-width: 80%;
      word-break: break-word;
      display: inline-block;
      text-align: left;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 1.43;
      color: #333333;
      border-radius: 10px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      background-color: #eaeff1;
      &.owned {
        margin-left: auto;
        text-align: right;
      }
      &.system {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      &.searched {
        background-color: $highlightColor;
      }
      &.focused {
      }
      &.selected {
        background-color: $color3;
      }
      .messageInfo {
        display: flex;
        flex-direction: row;
        padding-bottom: 8px;
        .messageTime {
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          margin-right: 10px;
        }
        .messageAuthor {
          font-size: 14px;
          line-height: 16px;
          color: #666666;
          span {
            vertical-align: top;
          }
        }
      }
      .messageContent {
        font-size: 15px;
        color: #333333;
      }
      .fileLink {
        font-size: 15px;
        color: #333333;
        cursor: pointer;
        text-decoration: underline;
      }
      .messageAppointment {
        .appointmentText {
          &.pending {}
          &.confirmed {}
          &.rejected {}
        }
        .appointmentButtons {
          display: flex;
          flex-direction: row;
          margin: 10px -14px -12px;
          border-top: 1px solid #dddddd;
          .reject,
          .confirm {
            padding: 5px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          .reject {
            width: 45%;
            color: $errorColor;
          }
          .confirm {
            width: 55%;
            color: $successColor;
            border-left: 1px solid #dddddd;
            label {
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
