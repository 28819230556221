@import '../_variables.scss';

.dashboardView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 45px 15px 30px;
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 70vh;
  box-shadow:
    0px 1px 3px 0px rgba(0,0,0,0.2),
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 2px 1px -1px rgba(0,0,0,0.12);
  .dashboardTitle {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 0px 15px;
  }
  .filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 32px;
    font-weight: 300;
    color: rgba(0,0,0,0.4);
    padding: 8px 15px;
    .filterTitle {
    }
    .filterItem {
      display: flex;
      flex-direction: row;
      &+.filterItem {
        &::before {
          content: '|';
          display: block;
          margin-left: 14px;
        }
      }
      &.active {
        span {
          color: rgba(0,0,0,0.8);
        }
      }
      span {
        display: block;
        cursor: pointer;
        margin-left: 14px;
      }
    }
  }
  .dashboardContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .dashboardBlock {
      width: 33.3333%;
      padding: 20px 15px;
      &.wide {
        width: 66.6666%;
      }
      .blockContent {
        border: 1px solid #d4d4d5;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        .blockTitle {
          font-size: 28px;
          font-weight: 400;
          color: #3b83c0;
          line-height: 40px;
          padding: 10px 14px;
          border-bottom: 1px solid #d4d4d5;
        }
        .blockMonth {
          font-size: 14px;
          font-weight: 300;
          color: rgba(0, 0, 0, 0.8);
          line-height: 20px;
          padding: 10px 14px;
          border-top: 1px solid #d4d4d5;
        }
        .blockData {
          &.investments {
            background-color: #70b0ab;
          }
          &.proposals {
            background-color: #f6d763;
          }
          &.users {
            background-color: #e48d8d;
          }
          .dataTotal {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0px 15px;
            span {
              color: #333333;
              font-size: 56px;
              font-weight: 300;
            }
            label {
              color: rgba(0,0,0,0.4);
              font-size: 14px;
              font-weight: 400;
            }
          }
          .dataDetails {
            width: 100%;
            max-width: 240px;
            margin: 0px auto;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .detailItem {
              color: rgba(0,0,0,0.8);
              font-size: 14px;
              font-weight: 300;
              line-height: 25px;
              padding-left: 21px;
              &::before {
                content: 'check_circle';
                font-family: 'Material Icons';
                display: inline-block;
                color: rgba(0,0,0,0.5);
                font-size: 15px;
                vertical-align: bottom;
                margin-right: 7px;
              }
              &.person {
                &::before {
                  content: 'person';
                }
              }
              &.schedule {
                &::before {
                  content: 'schedule';
                }
              }
              &.cancel {
                &::before {
                  content: 'cancel';
                }
              }
              &.pause {
                &::before {
                  content: 'pause';
                }
              }
            }
          }
        }
        .blockInvestmentsList {
          padding: 15px;
          table {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            tr {
              &:last-child {
                td {
                  border-bottom: none;
                }
              }
              td {
                padding: 10px 10px;
                border-bottom: 1px solid #d4d4d5;
                font-size: 14px;
                font-weight: 300;
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.8);
                &:first-child {
                  padding-left: 0px;
                }
                &:last-child {
                  padding-right: 0px;
                }
                .investmentLink {
                  color: #009fda;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .investmentStatus {
                  display: inline-block;
                  padding: 7px 10px;
                  font-size: 12px;
                  font-weight: 700;
                  color: rgba(0,0,0,0.6);
                  border-radius: 4px;
                  &.draft {
                    background-color:#999999;
                    color:#ffffff;
                  }
                  &.pending {
                    background-color:#ffe21f;
                  }
                  &.paid {
                    background-color:#3aa097;
                    color:#ffffff;
                  }
                  &.closed {
                    background-color:#d9499a;
                  }
                  &.live {
                    background-color:#3aa097;
                    color:#ffffff;
                  }
                  &.rejected {
                    background-color:#d95c5c;
                  }
                  &.expired {
                    background-color:#ff851b;
                    color:#ffffff;
                  }
                  &.complete {
                    background-color:#3b83c0;
                    color:#ffffff;
                  }
                  &.failed {
                    background-color:#d9499a;
                    color:#ffffff;
                  }
                }
              }
            }
          }
        }
        .blockChart {
          padding: 15px;
        }
      }
    }
  }
}
