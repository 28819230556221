@import '../../_variables.scss';

.logsList {
  padding: 15px 0px 10px;
  .logItem {
    border-top: 1px solid #cccccc;
    &:nth-child(2n+1) {
      background-color: #ffffff;
    }
    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
    .logInfo {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      padding: 0px 10px;
      .logUser {
        padding-left: 10px;
        color: #333333;
        text-overflow: ellipsis;
        overflow: hidden;
        &.clickable {
          color: $primaryColor;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .logTime {
        color: #999999;
        width: 130px;
        flex-shrink: 0;
      }
      .logType {
        color: #999999;
        width: 60px;
        flex-shrink: 0;
      }
      .expandButton {
        width: 60px;
        flex-shrink: 0;
        text-align: right;
        margin-left: auto;
        color: $primaryColor;
        font-weight: 600;
        padding: 10px 0px 10px 10px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
  .fieldView {
    width: 100%;
    padding: 2px 10px 15px;
    color: #333333;
  }
  .fieldsList {
    width: 100%;
    display: table;
    padding: 2px 10px 15px;
    .listHeader {
      display: table-row;
      font-weight: 500;
      color: #999999;
      .headerName,
      .headerChange {
        width: 33.3333%;
        display: table-cell;
        border-bottom: 1px solid #e5e5e5;
        padding: 5px 0px;
      }
      .headerChange {
        padding-left: 10px;
      }
    }
    .fieldItem {
      display: table-row;
      color: #333333;
      .fieldName,
      .fieldFrom,
      .fieldTo {
        display: table-cell;
        padding: 8px 0px 0px;
      }
      .fieldFrom,
      .fieldTo {
        padding-left: 10px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .logsList {
    .logItem {
      .logInfo {
        padding: 0px 6px;
      }
    }
  }
}
