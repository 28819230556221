@import '../_variables.scss';

.objectEditView {
	position: relative;
	.inputsBlock {
		display: flex;
		flex-direction: column;
		.inputContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			&.hidden {
				display: none;
			}
			label {
				width: 20%;
				max-width: 170px;
				display: block;
				font-size: 12px;
				font-weight: 700;
				color: #666666;
				text-transform: uppercase;
				text-align: right;
				padding-right: 15px;
			}
			label+.inputElem {
				width: 80%;
			}
			.inputElem {
				flex: 1 0;
				width: 100%;
				.inputError {
					position: absolute;
					color: $errorColor;
					font-size: 12px;
					margin: -5px 0px 0px;
				}
			}
		}
	}
	.buttonContainer {
		padding: 15px 0px 5px;
		&:not(.left) {
			&::before {
				content: '';
				display: inline-block;
				width: 20%;
				max-width: 170px;
			}
		}
	}
}

@media all and (max-width: 768px) {
	.objectEditView {
		.inputsBlock {
			.inputContainer {
				padding: 10px 0px 0px;
				flex-direction: column;
				label {
					padding-right: 0px;
					width: 100%;
					max-width: initial;
					text-align: left;
				}
				.inputElem {
					width: 100%;
				}
			}
		}
		.buttonContainer {
			&::before {
				display: none;
			}
		}
	}
}
