@import '../_variables.scss';

.documentsViewItem {
  td {
    padding: 5px;
    border-bottom: 1px solid #cccccc;
  }
  .textCell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .selectInput {
    padding: 0;
  }
  .documentClickable {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      .pageLink {
        text-decoration: underline;
      }
    }
  }
  .pageLink {
    width: 100%;
    cursor: pointer;
    color: $primaryColor;
    padding-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .fileCell {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    input {
      width: 100%;
      min-width: 0;
      border: none;
      border-bottom: 1px solid #dddddd;
      padding: 0 5px;
      border-radius: 0;
      color: #333333;
      font-style: 15px;
    }
  }
  .actionButton {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .material-icons {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .thumbnail {
    height: 40px;
    width: 40px;
    background-size: cover;
    background-position: center;
    margin-right: 5px;
  }
}

@media all and (max-width: 768px) {
  .documentsViewItem {
    &.embedded {
      margin: -10px 0 45px;
    }
  }
}
