.todoList {
  padding: 16px 0px 18px;
  .listContainer {
    .listItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0px;
      .checkboxInput {
        padding: 0px;
      }
      .textInput {
        padding: 0px;
        width: 100%;
        margin: 0px 8px;
        input {
          line-height: 16px;
          border-width: 0px 0px 1px 0px;
          border-radius: 0px;
        }
      }
      .removeButton {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: rgba(0, 0, 0, 0.08);
        }
        .material-icons {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
  button {
    margin-top: 8px;
  }
}
