@import '../../_variables.scss';

.pageLink {
  padding: 10px 0px;
  span {
    display: block;
    text-decoration: underline;
    color: $primaryColor;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
