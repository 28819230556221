@import '../../_variables.scss';

.dropdownMenu {
  .buttonElement {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
    &>* {
      display: inline-block;
    }
    cursor: pointer;
  }
  .menuOverlay {
    position: fixed;
    top: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 900;
    &.hidden {
      display: none;
    }
  }
  .menu {
    position: fixed;
    top: 10px;
    left: 18px;
    right: 18px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: left;
    z-index: 950;
    transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: translate(0%, 0%) scale(1.0, 1.0);
    &.expandTop {
      transform: translate(0%, -100%) scale(1.0, 1.0);
    }
    &.hidden {
      transform: translate(calc(10px - 50%), calc(10px - 50%)) scale(0.0, 0.0);
      &.right {
        transform: translate(calc(50% - 10px), calc(10px - 50%)) scale(0.0, 0.0);
      }
      &.windowCenter {
        transform: translate(0%, -50%) scale(0.0, 0.0);
      }
    }
    &.windowCenter {
      top: 50%;
      transform: translate(0%, -50%) scale(1.0, 1.0);
    }
    .menuItem {
      position: relative;
      width: 100%;
      padding: 10px;
      margin: 5px 0px;
      cursor: pointer;
      white-space: nowrap;
      &.danger {
        .menuItemTitle {
          color: $errorColor;
        }
      }
      &.disabled {
        cursor: not-allowed;
        .menuItemTitle {
          color: #cccccc;
        }
      }
      .menuItemTitle {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      .menuItemDescription {
        margin-top: 4px;
        font-size: 12px;
        color: #666666;
      }
    }
  }
}

@media all and (min-width: 768px) {
  .dropdownMenu {
    .menu {
      left: initial;
      right: initial;
      width: auto;
      max-width: 420px;
      transform: translate(-100%, 0%) scale(1.0, 1.0);
      &.expandTop {
        transform: translate(-100%, -100%) scale(1.0, 1.0);
      }
      &.hidden {
        transform: translate(calc(10px - 150%), calc(10px - 50%)) scale(0.0, 0.0);
        &.right {
          transform: translate(calc(-50% - 10px), calc(10px - 50%)) scale(0.0, 0.0);
        }
        &.windowCenter {
          transform: translate(-50%, -50%) scale(0.0, 0.0);
        }
      }
      &.windowCenter {
        top: 40%;
        left: calc(50% + 110px);
        transform: translate(-50%, -50%) scale(1.0, 1.0);
      }
    }
  }
}
