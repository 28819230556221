@import '../_variables.scss';

.headerView {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: $primaryColor;
  height: 54px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  .backLink,
  .menuButton {
    padding: 5px;
    margin: 0px 5px;
		cursor: pointer;
		display: flex;
    align-items: center;
    color: #ffffff;
		.material-icons {
			font-size: 28px;
    } 
  }
  .pageHeader {
    padding: 0px 15px 0px 15px;
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.5px;
    display: inline-block;
    width: calc(100% - 130px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .optionsButton {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
