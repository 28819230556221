.textInput {
  padding: 8px 0px;
  &.simplified {
    input,
    input[readonly],
    textarea,
    textarea[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  input,
  textarea {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
    &::placeholder {
      color: #999999;
    }
    &[type=number]::-webkit-inner-spin-button, 
    &[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }
  textarea {
    resize: none;
    min-height: 100px;
    font-family: inherit;
  }
}
