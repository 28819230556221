@import '../../variables';

.invoicesView {
  position: relative;
  width: 100%;
  padding: 10px 0px 5px;
  &.list {
    padding: 0px;
    button {
      margin: 0px;
      padding: 8px 10px;
    }
  }
  button {
    margin: 10px 0px 5px;
  }
  .invoicesTable {
    table-layout: fixed;
    width: 100%;
    border: none;
    border-collapse: collapse;
    font-size: 15px;
    margin-bottom: 10px;
    thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
          font-weight: 400;
          text-align: left;
          padding: 0px 5px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px 5px;
          overflow: visible;
          border-top: 1px solid #dddddd;
          &>* {
            padding: 0px;
          }
          &.control {
            padding: 0px 5px;
            overflow: visible;
          }
        }
      }
    }
  }
}

.invoiceForm {
  display: flex;
  flex-direction: column;
  label {
    display: block;
    margin: 5px 0px 0px;
  }
}
