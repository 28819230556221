@import '../../_variables.scss';

.contentPopup {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 36px);
      position: relative;
      max-width: 448px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px,
        rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px,
        rgba(0, 0, 0, 0.02) 0px 12px 16px;
      max-height: 100%;
      border-radius: 8px;
      transition: all 300ms ease 0s;
      padding: 20px;
      position: relative;
      .popupTitle {
        padding-right: 40px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.22;
        color: #333333;
      }
      .closeButton {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(../../../../public/images/icons/close.svg) no-repeat center;
          mask-size: contain;
        }
      }
      .contentContainer {
        padding: 20px 0px 0px;
      }
      .popupControls {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        .popupControl {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 700;
          color: #ffffff;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $primaryColor;
          text-transform: uppercase;
          cursor: pointer;
          &.cancel {
            background-color: #ffffff;
            color: $primaryColor;
          }
          &.confirm {
            background-color: $primaryColor;
            color: #ffffff;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
